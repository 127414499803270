@import url(./colors.css);
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@700&display=swap');

@font-face {
  font-family: 'Gotham Medium';
  src: local('Gotham Medium'), url('./fonts/GothamMediumRegular.ttf');
}

html {
  background: var(--color-background);
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  font-family: 'Red Hat Text', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
