@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@700&display=swap);
:root {
  --color-primary: #f15f3a;
  --color-primary-level-1: #ffd4ff;
  --color-primary-level-2: #ff87ff;
  --color-primary-level-6: #663b66;
  --color-secondary: #27aae1;
  --color-secondary-level-1: #e3e3ff;
  --color-secondary-level-2: #bfbffc;
  --color-secondary-level-6: #313166;
  --color-tertiary: #55ffff;
  --color-tertiary-level-1: #d4ffff;
  --color-tertiary-level-2: #a0f2f2;
  --color-tertiary-level-6: #499494;
  --color-positive: #53db53;
  --color-positive-level-1: #e3ffe3;
  --color-positive-level-2: #b5ffb5;
  --color-positive-level-6: #569656;
  --color-caution: #ffc555;
  --color-caution-level-1: #fff6e5;
  --color-caution-level-2: #ffe5b2;
  --color-caution-level-6: #b27805;
  --color-negative: #ff5555;
  --color-negative-level-1: #ffeded;
  --color-negative-level-2: #ff8787;
  --color-negative-level-6: #803d3d;
  --color-background: #ffffff;
  --color-foreground: #546474;
  --color-foreground-level-1: #909ba7;
}

[data-theme="dark"] {
  --color-primary: #ff55ff;
  --color-primary-level-1: #382847;
  --color-primary-level-2: #62326d;
  --color-primary-level-6: #ffd4ff;
  --color-secondary: #5555ff;
  --color-secondary-level-1: #212847;
  --color-secondary-level-2: #2c326d;
  --color-secondary-level-6: #e3e3ff;
  --color-tertiary: #55ffff;
  --color-tertiary-level-1: #214047;
  --color-tertiary-level-2: #2c326d;
  --color-tertiary-level-6: #e3e3ff;
  --color-positive: #53db53;
  --color-positive-level-1: #21402f;
  --color-positive-level-2: #2c6837;
  --color-positive-level-6: #e3ffe3;
  --color-caution: #ffc555;
  --color-caution-level-1: #2f312d;
  --color-caution-level-2: #524a34;
  --color-caution-level-6: #ffefcf;
  --color-negative: #ff5555;
  --color-negative-level-1: #38282f;
  --color-negative-level-2: #623237;
  --color-negative-level-6: #ffd4d4;
  --color-background: #182129;
  --color-foreground: #ffffff;
  --color-foreground-level-1: #242e38;
  --color-foreground-level-2: #29343d;
  --color-foreground-level-3: #333e47;
  --color-foreground-level-4: #5e6d7a;
  --color-foreground-level-5: #8594a1;
  --color-foreground-level-6: #d3dee8;
}

@font-face {
  font-family: 'Gotham Medium';
  src: local('Gotham Medium'), url(/static/media/GothamMediumRegular.0b28710a.ttf);
}

html {
  background: var(--color-background);
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  font-family: 'Red Hat Text', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

